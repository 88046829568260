<!-- @format -->

<template>
  <div>
    <div class="mobile-cast">
      <div class="mob-content">
        <div class="mob-cast">
          <div class="mob-person-cast" :class="$mq">
            <span>MARIJA</span>
            Emilia Schüle
          </div>
          <div class="mob-person-cast" :class="$mq">
            <span>CURT</span>
            Günther Maria Halmer
          </div>
          <div class="mob-person-cast" :class="$mq">
            <span>PHILIPP</span>
            Fabian Hinrichs
          </div>
          <div class="mob-person-cast" :class="$mq">
            <span>ALMUT</span>
            Anna Stieblich
          </div>
          <div class="mob-person-cast" :class="$mq">
            <span>ARINA</span>
            Masha Tokareva
          </div>
          <div class="mob-person-cast" :class="$mq">
            <span>TINJA</span>
            Yanina Lisovskaya
          </div>
          <div class="mob-person-cast" :class="$mq">
            <span>ARTJOM</span>
            Daniel Breder
          </div>
          <div class="mob-person-cast" :class="$mq">
            <span>HEIDRUN</span>
            Hede Beck
          </div>
          <div class="mob-person-cast" :class="$mq">
            <span>HERR SIEVERT</span>
            Fritz Roth
          </div>
          <div class="mob-person-cast" :class="$mq">
            <span>SANITÄTER</span>
            Ricky Watson
          </div>
          <div class="mob-person-cast" :class="$mq">
            <span>SVENJA</span>
            Sina Bianca Hentschel
          </div>
        </div>
        <div class="mob-crew">
          <div class="mob-person" :class="$mq">
            <span>Drehbuch und Regie</span>
            Nadine Heinze und Marc Dietschreit
          </div>
          <div class="mob-person" :class="$mq">
            <span>Produzenten</span>
            Marc Müller-Kaldenberg, Pascal Nothdurft
          </div>
          <div class="mob-person" :class="$mq">
            <span>Koproduzentin</span>
            Tanja Ziegler
          </div>
          <div class="mob-person" :class="$mq">
            <span>Produktion</span>
            Zieglerfilm Baden-Baden
          </div>
          <div class="mob-person" :class="$mq">
            <span>in Koproduktion mit</span>
            Ziegler Film, SWR (Redaktion: Stefanie<br />
            Groß), ARTE (Redaktion: Barbara Häbe)
          </div>
          <div class="mob-person" :class="$mq">
            <span>Produktionsleitung</span>
            Robert Geisler
          </div>
          <div class="mob-person" :class="$mq">
            <span>Bildgestaltung</span>
            Holly Fink
          </div>
          <div class="mob-person" :class="$mq">
            <span>Schnitt</span>
            Andrea Mertens
          </div>
          <div class="mob-person" :class="$mq">
            <span>Szenenbild</span>
            Caro Ohmert
          </div>
          <div class="mob-person" :class="$mq">
            <span>Kostüm</span>
            Monika Gebauer
          </div>
          <div class="mob-person" :class="$mq">
            <span>Maske</span>
            Nicolay Lißner, Anna Kunz
          </div>
          <div class="mob-person" :class="$mq">
            <span>Originalton </span>
            Rüdiger Fleck
          </div>
          <div class="mob-person" :class="$mq">
            <span>Casting</span>
            Emrah Ertem
          </div>
          <div class="mob-person" :class="$mq">
            <span>Musik</span>
            Daniel Sus, Can Erdogan
          </div>
          <div class="mob-person" :class="$mq">
            <span>Gefördert durch</span>
            MFG Medien- und Filmgesellschaft<br />
            Baden-Württemberg, DFFF Deutscher Filmförderfonds,<br />
            FFA Filmförderungsanstalt
          </div>
          <div class="mob-person" :class="$mq">
            <span>Kofinanziert von</span>
            Musikverlag EDITION TANJA
          </div>
          <div class="mob-person" :class="$mq">
            <span>Verleih Deutschland</span>
            Filmwelt Verleihagentur
          </div>
        </div>
      </div>
      <router-link to="/mobile" class="mobile-cast__btn-close">
        <Arrow /> Zurück zur Webseite
      </router-link>
    </div>
  </div>
</template>

<script>
  import Arrow from 'vue-material-design-icons/ArrowLeftBoldCircle'
  import moment from 'moment'

  export default {
    components: {
      Arrow,
    },
    data() {
      return {
        num: 0,
      }
    },
    computed: {
      kinostart() {
        return moment() < moment(this.$config.release_line_1.date)
          ? this.$config.release_line_1.line_before
          : this.$config.release_line_1.line_after
      },
      active() {
        return `fontWeight: 900 !important;
                    color: black;`
      },
    },
  }
</script>

<style lang="scss">
  .mobile-cast {
    $self: &;
    margin-top: 15px;
    .mob-content {
      margin-top: 22px;
      width: 100%;
      display: flex;
      flex-direction: column;
      .mob-cast {
        margin-bottom: 15px;
        width: 100%;
        text-align: left;
        .mob-person-cast {
          $min_width: 1024px;
          $max_width: 2560px;
          $min_font: 16px;
          $max_font: 19px;
          @include fluid-type($min_width, $max_width, $min_font, $max_font);
          color: black;
          span {
            font-weight: 700;
            font-size: 19px;
            color: #259aa4;
          }
        }
      }
      .mob-crew {
        text-align: left;
        width: 100%;
        .mob-person {
          $min_width: 1024px;
          $max_width: 2560px;
          $min_font: 16px;
          $max_font: 19px;
          @include fluid-type($min_width, $max_width, $min_font, $max_font);
          color: black;
          span {
            font-weight: 700;
            font-size: 19px;
            color: #259aa4;
          }
        }
      }
    }

    &__btn-close {
      padding: 0 5%;
      color: white;
      font-weight: 700;
    }
  }
</style>
