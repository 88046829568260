<!-- @format -->

<template>
  <div>
    <MobileHeader :social="true" />
    <MobileTop class="mob-index" id="mob-index" />

    <div class="grass-mobile" :class="$mq">
      <div class="grass-mob" :class="$mq">
        <div
          :class="$mq"
          class="click"
          onclick="window.open('https://www.amazon.de/Die-Vergesslichkeit-Eichh%C3%B6rnchen-Emilia-Sch%C3%BCle/dp/B09FC5VPJ6/ref=sr_1_2?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=2R5G4C2CD4CQM&dchild=1&keywords=die+vergesslichkeit+der+eichh%C3%B6rnchen&qid=1635243344&qsid=262-1034553-2302661&sprefix=die+vergesslic%2Caps%2C272&sr=8-2&sres=B099ZY5ZVD%2CB09FC5VPJ6%2CB01K8Y6XC6%2CB019ZS57SW%2C3865662676%2CB07N2Y2HPM%2CB07PKSFJZ6%2CB07JZK4QSG%2CB0100YUSCK%2CB08NY7YNJ4%2C3836960303%2C3945302137%2C3948638284%2C3981626273%2C1079725784%2C3969670683&srpt=DOWNLOADABLE_MOVIE', '_blank');"
        ></div>
        <div class="tt mobile-trailer" :class="$mq" id="mobile-trailer">
          <img src="@/assets/images/mobile/tt.png" />
        </div>
        <div class="mobile-cast" :class="$mq" id="mobile-cast">
          <img src="@/assets/images/mobile/cast.png" />
        </div>
        <MobileTrailerSizzle />
      </div>
    </div>
    <!--    <div class="content">
      
      <div class="cites">
        <div class="text">„Ein hervorragender Dokumentarfilm“</div>
        <div class="autor">ttt -Titel, Thesen, Temperamente</div>
      </div>
      <div class="inhalt-top">
        <div class="heading">
          <div class="content">
            <div class="cell">3 Schauspielerinnen</div>
            <div class="cell">3 Kinderzimmer</div>
            <div class="cell">10 Tage</div>
            <div class="cell">2.458 Männer</div>
            <div class="cline">mit eindeutigen Absichten</div>
          </div>
        </div>
      </div>
    </div>-->
    <div class="mobile__packshot" :class="$mq">
      <a
        href="https://www.amazon.de/Die-Vergesslichkeit-Eichh%C3%B6rnchen-Emilia-Sch%C3%BCle/dp/B09FC5VPJ6/ref=sr_1_2?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=2R5G4C2CD4CQM&dchild=1&keywords=die+vergesslichkeit+der+eichh%C3%B6rnchen&qid=1635243344&qsid=262-1034553-2302661&sprefix=die+vergesslic%2Caps%2C272&sr=8-2&sres=B099ZY5ZVD%2CB09FC5VPJ6%2CB01K8Y6XC6%2CB019ZS57SW%2C3865662676%2CB07N2Y2HPM%2CB07PKSFJZ6%2CB07JZK4QSG%2CB0100YUSCK%2CB08NY7YNJ4%2C3836960303%2C3945302137%2C3948638284%2C3981626273%2C1079725784%2C3969670683&srpt=DOWNLOADABLE_MOVIE"
        target="_blank"
        ><img src="@/assets/images/packshot.png" alt=""
      /></a>
    </div>
    <div class="btns">
      <div class="amazon">
        <a
          href="https://www.amazon.de/Die-Vergesslichkeit-Eichh%C3%B6rnchen-Emilia-Sch%C3%BCle/dp/B09FC5VPJ6/ref=sr_1_2?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=2R5G4C2CD4CQM&dchild=1&keywords=die+vergesslichkeit+der+eichh%C3%B6rnchen&qid=1635243344&qsid=262-1034553-2302661&sprefix=die+vergesslic%2Caps%2C272&sr=8-2&sres=B099ZY5ZVD%2CB09FC5VPJ6%2CB01K8Y6XC6%2CB019ZS57SW%2C3865662676%2CB07N2Y2HPM%2CB07PKSFJZ6%2CB07JZK4QSG%2CB0100YUSCK%2CB08NY7YNJ4%2C3836960303%2C3945302137%2C3948638284%2C3981626273%2C1079725784%2C3969670683&srpt=DOWNLOADABLE_MOVIE"
          target="_blank"
          ><img src="@/assets/images/amazon_btn.png" alt=""
        /></a>
      </div>
      <div class="itunes">
        <a
          href="https://itunes.apple.com/ch/movie/die-vergesslichkeit-der-eichh%C3%B6rnchen/id1574867657"
          target="_blank"
          ><img src="@/assets/images/itunes_btn.png" alt=""
        /></a>
      </div>
    </div>
    <div class="mob-cline">
      <img src="@/assets/images/mobile/mobile_cline.png" />
    </div>
    <!-- Inhalt -->
    <div id="mob-inhalt">
      <MobileExpandableCard
        class="mob-inhalt"
        image="card_inhalt.jpg"
        btnTopAlign="left"
        btnTopColor="#259aa4"
        btnTopBg="#fff"
        btnBottomColor="#259aa4"
        btnBottomBg="#fff"
        cardBorderRadius="0px"
        imageAlign="center"
      >
        <!-- H1 Headline -->
        <template v-slot:headline> Inhalt </template>
        <!-- Teaser -->
        <template v-slot:teaser>
          <div v-html="$texte.inhalt.teaser"></div>
        </template>
        <!-- Hidden Maincontent -->
        <div v-html="$texte.inhalt.text"></div>
      </MobileExpandableCard>
    </div>
    <div class="mobile-bild1" :class="$mq">
      <img src="@/assets/images/mobile/mobile_bild1.jpg" />
    </div>
    <div id="mob-fotos" class="mob-fotos">
      <BaseCarousel
        :autoplay="false"
        :preview="true"
        :fade="true"
        :fullscreen="true"
        :dots="true"
      >
        <div class="source">
          <div
            v-for="value in this.$config.gallerymobile.count"
            :key="value"
            class="carousel-cell"
          >
            <img
              :src="require(`@/assets/images/gallery/mobile/` + value + `.jpg`)"
              alt
              class="pictures"
            />
          </div>
        </div>
      </BaseCarousel>
    </div>
    <div class="mobile-bild2">
      <img src="@/assets/images/mobile/mobile_bild2.jpg" />
    </div>
    <div id="mob-crew">
      <MobileExpandableCard
        class="mob-crew"
        image="card_crew.jpg"
        btnTopAlign="left"
        btnTopColor="#259aa4"
        btnTopBg="#fff"
        btnBottomColor="#259aa4"
        btnBottomBg="#fff"
        cardBorderRadius="0px"
        imageAlign="center"
      >
        <!-- H1 Headline -->
        <template v-slot:headline> CAST & CREW </template>
        <!-- Teaser -->
        <template v-slot:teaser>
          <div v-html="$texte.crew.teaser"></div>
        </template>
        <!-- Hidden Maincontent -->
        <crew-mobile />
      </MobileExpandableCard>
    </div>
    <!--    <div class="fotos">
      <div class="image_grid">
        <div class="col">
          <div class="fullpic">
            <img src="@/assets/images/mobile/pic1.jpg" @click="foto = 0" />
          </div>
          <div class="row">
            <div class="pic">
              <img src="@/assets/images/mobile/pic2.jpg" @click="foto = 1" />
            </div>
            <div class="pic">
              <img src="@/assets/images/mobile/pic3.jpg" @click="foto = 2" />
            </div>
          </div>
          <div class="fullpic">
            <img src="@/assets/images/mobile/pic4.jpg" @click="foto = 3" />
          </div>
          <div class="row">
            <div class="pic">
              <img src="@/assets/images/mobile/pic5.jpg" @click="foto = 4" />
            </div>
            <div class="pic">
              <img src="@/assets/images/mobile/pic6.jpg" @click="foto = 5" />
            </div>
          </div>
          <div class="fullpic">
            <img src="@/assets/images/mobile/pic7.jpg" @click="foto = 6" />
          </div>
        </div>
      </div>
    </div>-->

    <BaseFooter :kinostart="kinostart" :billing="true" :youtubenote="false" />
    <BaseModalGallery :foto="foto" />
  </div>
</template>

<script>
  import moment from 'moment'

  import texte from '@/views/texte/all.json'
  import CrewMobile from '../views/CrewMobile.vue'

  export default {
    components: { CrewMobile },
    data() {
      return {
        foto: null,
        images: [],
        texte: texte,
      }
    },
    computed: {
      kinostart() {
        return moment() < moment(this.$config.release_line_1.date)
          ? this.$config.release_line_1.line_before
          : this.$config.release_line_1.line_after
      },
      ratio() {
        return (
          this.$store.getters['Window/isSize'].width /
          this.$store.getters['Window/isSize'].height
        )
      },
      trailer() {
        return this.$config.youtube.id !== ''
      },
    },
    mounted() {
      for (let index = 0; index < this.$config.gallery.count; index++) {
        this.images[index] = require(`@/assets/images/gallery/` +
          this.pad(index + 1, 1) +
          `.jpg`)
      }
    },
    methods: {
      pad(num, size) {
        let s = num + ''
        while (s.length < size) s = '0' + s
        return s
      },
    },
  }
</script>

<style lang="scss">
  body {
    background-color: #030204;
  }
  .mob-top {
    z-index: -1;
  }
  .mob-inhalt {
    z-index: 2;
  }
  .mob-crew {
    z-index: 2;
  }
  .grass-mobile {
    margin-top: -130px;
    z-index: 2;
    &.sm {
      margin-top: -100px;
    }
    .grass-mob {
      z-index: 2;
      width: 100%;
      height: 100%;
      background: transparent url($assets + 'images/mobile/grass_mobile.png')
        center top no-repeat;
      background-size: cover;
      overflow: hidden;
      display: flex;
      padding-bottom: 200px;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .click {
        cursor: pointer;
        height: 500px;
        width: 300px;
        position: absolute;
        top: 650px;
        right: 0;
        z-index: 99999;
        &.sm {
          top: 450px;
          height: 450px;
          width: 300px;
        }
      }
      .tt {
        margin-left: 30px;
      }
      .mobile-trailer {
        margin-top: 60px;
        margin-bottom: 10px;
        width: 90%;
        img {
          width: 100%;
        }
      }
      .mob-eich {
        position: absolute;
        bottom: -65%;
        width: 40%;
        img {
          width: 100%;
        }
      }
      .mobile-cast {
        margin-top: 0;
        margin-bottom: 20px;
        width: 90%;
        img {
          width: 100%;
        }
      }
    }
  }
  .mobile__packshot {
    margin-top: -190px;
    width: 100%;
    img {
      width: 100%;
    }
  }
  .btns {
    background: #fff url($assets + 'images/btn_bg.png') center top;
    display: flex;
    flex-direction: row;
    padding: 20px 0 10px 0;
    .amazon {
      padding-left: 7.8%;
      padding-right: 1%;
    }
    .itunes {
      padding-left: 1%;
      padding-right: 7.8%;
    }
  }
  .mob-cline {
    margin-bottom: -30px;
  }
  #mob-inhalt {
    width: 100%;
    height: 100%;
    background: #fff url($assets + 'images/mobile/bg_inhalt_mobile.jpg') center
      top;
  }
  #mob-crew {
    background-color: #259aa4;
    margin-top: -30px;
  }
  .mobile-bild2,
  .mobile-bild1,
  #mob-fotos,
  #mob-top,
  .mobile-eichhoernchen,
  .mob-cline {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .flickity-prev-next-button.previous {
    display: none;
  }
  .flickity-prev-next-button.next {
    display: none;
  }
  .flickity-page-dots .dot.is-selected {
    background-color: #259aa4;
  }
  /*  .fotos {
    position: relative;
    width: 100%;
    height: auto; // z-index: 1;
    padding-bottom: 0;
    padding-top: 40px;
    .image_grid {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;

      img {
        cursor: pointer;
      }

      .col {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .row {
          display: flex;
          flex-direction: row;
        }

        .pic {
          width: 50%;
          overflow: hidden;
          a {
            img {
              width: 100%;
            }
          }
        }
      }
    }
  } */
</style>
